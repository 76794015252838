$highlight-color:   	#0070FF
$tint-color:        	#444
$light-color:       	rgba(0, 0, 0, 0.6)
$light-border-color:	#DFDFDF
$offwhite-gray:         #F0F0F0

//BOROUGH
$boro-mn:				#51AE4F
$boro-bk:				#FFCE2F
$boro-qu:				#FE8023
$boro-bx:				#E21F27
$boro-si:				#9851A2

//AREA
$area-ny:				black
$area-sf:				black
$area-la:				black

//RECCOMENDATION ICONS
$battery:				#05D748
$bulb:					#ff6c00
$sun:					#FFBC00
$hydrogen:              #01A3FF
$solar_thermal:		    #ffbc00
