@import '../../sass/colors'

.loader-container
  width: 10rem
  padding: 1rem
  overflow: hidden
  margin: 30vh auto
  #details &
    margin-top: 50%
    transform: translateY(-50%)
  .loader
    transform-origin: center center
    top: 0
    bottom: 0
    left: 0
    right: 0
    width: 100%
    stroke-dasharray: 1, 200
    stroke-dashoffset: 0
    animation: loader-dash 1s ease-in-out infinite,  loader-rotate 2s linear infinite
    stroke-linecap: round
    stroke: $highlight-color

  @keyframes loader-rotate
    100%
      transform: rotate(360deg)

  @keyframes loader-dash
    0%
      stroke-dasharray: 1, 200
      stroke-dashoffset: 0
    50%
      stroke-dasharray: 89, 200
      stroke-dashoffset: -35px
    100%
      stroke-dasharray: 89, 200
      stroke-dashoffset: -124px