@import '../../sass/colors'
@import url('https://fonts.googleapis.com/css2?family=Archivo&display=swap')

.map-container
  display: flex
  height: 280px
  overflow: hidden
  #pano
    width: 100%
  #pano
    width: 100%
    overflow: hidden
    height: 100%
  #map-view
    width: 100%

.building
  position: relative
  width: 100%
  height: 100%
  z-index: 20
  overflow: auto
  #nearest-powerline
    label
      text-shadow: 1px 1px 5px #fff
    .row
      position: relative
    .info
      position: absolute
      top: 1.5rem
      left: 1rem
      z-index: 2
  .chart-controls
    margin-top: 0.8rem
    button
      margin-right: 0.6rem

  #drop-zone
    position: absolute
    width: 100%
    height: 100%
    background: rgba(255, 255, 255, 0.9)
    z-index: 8000
    display: flex
    align-items: center
    justify-content: center
    color: #004399
    text-transform: uppercase
    font-size: 2rem
    pointer-events: none
    opacity: 0

    &.visible
      opacity: 1
    .center
      text-align: center

  #files
    table
      td
        text-align: left
      th:first-child
        width: 42px


  &.blurred
    display: flex
    justify-content: center
    align-items: center
    .error-message
      h3
        font-size: 1.5rem

  #chart-container
    margin-top: 1rem

  .address_ranges
    padding-top: 0.4rem
    letter-spacing: 0
    span
      margin-right: 1rem

  .info-text
    font-style: italic

  .close
    position: absolute
    top: 12px
    right: 10px
    width: 40px
    height: 40px
    background: yellow
    z-index: 9999
    cursor: pointer
    background: url('../../assets/btn-close.svg')
    background-size: contain
    background-repeat: no-repeat

  .container
    display: flex
    //max-width: 900px
    height: auto
    flex-direction: column
    color: $tint-color
    background: white
    .header
      padding-top: 2vw
      padding-left: calc(16px + 1vw)
    .col-one
      flex: 2
      display: flex
      align-items: flex-start
      justify-content: center
    .col-two
      flex: 7
      display: flex
      justify-content: flex-start
      flex-direction: column
      padding-top: 0
      padding-left: 0
    h3
      flex: 3
      color: $light-color
    .body
      padding-left: calc(16px + 1vw)
      position: relative
      flex: 1
      display: flex
      .shadow
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 1rem
        background: yellow
      .content
        flex: 1
        padding: 0 0 1rem 0
        height: inherit
        overflow-y: auto
        overflow-x: hidden
    .header
      display: flex
      position: relative
      .boro
        margin-top: 0
        margin-right: -1rem
        display: flex
        align-items: center
        justify-content: center
        width: calc(48px + 2vw)
        height: calc(48px + 2vw)
        color: white
        border-radius: 999px
        font-weight: 800
        text-transform: uppercase
        font-size: 2rem

        &.mn
          background: $boro-mn
        &.bk
          background: $boro-bk
        &.qu
          background: $boro-qu
        &.bx
          background: $boro-bx
        &.si
          background: $boro-si

      .area
        margin-top: -1rem
        margin-right: -1rem
        display: flex
        align-items: center
        justify-content: center
        width: calc(48px + 2vw)
        height: calc(48px + 2vw)
        color: white
        border-radius: 999px
        font-weight: 800
        text-transform: uppercase
        font-size: 2rem
        background: lightgray
        &.ny
          background: $area-ny
        &.sf
          background: $area-sf
        &.la
          background: $area-la

.building--header
  position: relative
  &:hover
    .building--title
      opacity: 0
      visibility: hidden
      pointer-events: none

.building--title
  position: absolute
  bottom: 2rem
  left: 2rem
  right: 2rem
  z-index: 100
  color: #fff
  transition: all .3s ease-in-out
  h1
    font-size: 1.8rem
    line-height: 100%
    margin: 0
  h2
    margin: 0
  h3
    text-transform: uppercase
    font-weight: 400
    line-height: 140%
    font-size: 1rem
    margin: 0
    letter-spacing: 0.05rem

.building--energy
  display: flex
  align-items: center
  .icon
    display: flex
    margin-right: 1rem
  span
    display: block
    font-size: .9rem
    font-weight: normal
  p
    font-weight: bold
    font-size: 1rem
    margin-bottom: 0
    margin-top: .5rem
    &.none
      color: $light-color
  .der-icon,
  .energy-star-icon
    width: 3rem
    height: 3rem
  .energy-star-icon
    background: url('../../assets/icon-leaf.svg') no-repeat 50% 50% / contain
    margin-top: -.1rem
  .der-icon
    background: url('../../assets/icon-bolt.svg') no-repeat 50% 50% / contain

.building--details
  background: #fff
  padding: 16px
  @media (min-width: 960px)
    padding: 16px 24px 48px

  @media (min-width: 1280px)
    padding: 16px 48px 48px

.details-map-container
  margin: 16px 0 0

.subscribeButton
  background-color: #4c4c4c
  border: none
  color: white
  padding: 10px 20px
  text-align: center
  text-decoration: none
  display: inline-block
  margin: 15px 12px
  cursor: pointer
  border-radius: 25px

.sectionDivider
  background-color: #f3f7f7
  width: 100% 
  padding: 10px
      
.subHeader  
  background-color: #f5f5f5
  width: 100% 
  padding: 10px

.headerText
  color: #a7a7a7
  font-family: 'Archivo', sans-serif
  font-weight: bold
  font-size: 10pt
  text-transform: uppercase
  max-width: 150px
  text-align: center

.dataText
  font-weight: bold
  -webkit-user-select: none
  -webkit-touch-callout: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none


.blur
  filter: blur(4px)
  -webkit-user-select: none
  -webkit-touch-callout: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.backdropBlur
  filter: blur(15px)

.card 
  cursor: pointer

  &:hover
    transition: 0.4s ease-in-out
    transform: scale(1.01)
    box-shadow: -1px 10px 29px 0px rgba(0,0,0,0.8)

  

.bottomGradient
  fontFamily: archivo
  position: absolute
  width: 100%
  background: linear-gradient(0deg, rgba(17,17,17,1) 0%, rgba(17,17,17,1) 30%, rgba(17,17,17,0.2147233893557423) 81%, rgba(17,17,17,0) 100%)
  height: 599px
  margin-top:-600px

.arrow-pointer 
  width: 150px
  height: 30px
  background: #3aa4ff
  position: absolute
  right: 0
  
  &:after 
    content: ''
    position: absolute
    left: -20px 
    bottom: 0 
    width: 0 
    height: 0
    border-left: 20px solid #3aa4ff
    border-top: 15px solid transparent
    border-bottom: 15px solid transparent
    transform: rotate(180deg)

.statusBox
  @media(max-width:600px)
    visibility: hidden

.energyUseChart
  @media(max-width:600px)
    display: none
  


  