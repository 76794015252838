@import '../../sass/colors'

.data-section
  height: 100%
  & > .data-section-header
    position: sticky
    top: 0
    display: flex
    align-items: center
    padding: 16px
    margin: 0 -16px
    font-weight: 700
    text-transform: uppercase
    line-height: 2rem
    z-index: 10

    button
      margin: -16px 0 -16px -16px

  .data-section-header + small
    text-transform: uppercase
    margin: 0.3rem auto
    display: block
    font-size: 0.7rem

  .data-section-content
    padding: 16px 0 16px

  .table
    width: 100%
    .row
      display: flex
      width: 100%
      padding: 1rem
      &:nth-child(odd)
        background: rgba(0, 0, 0, .035)
      .col
        flex: 1

  .table.clear
    padding-top: 0rem
    margin-bottom: -2rem
    .row
      padding: 0.6rem 1rem 1rem .1rem
      &:nth-child(odd)
        background: transparent

  .recommendations
    .recommendations-item
      margin-bottom: 16px
      &:last-child
        margin-bottom: 0
      label
        font-weight: bold
        font-size: 0.6rem
        letter-spacing: 0.025rem
        text-transform: uppercase
        color: $light-color
        cursor: help

        .info-label
          font-size: 0.8rem
          text-align: left
          padding: 10px
          text-transform: initial
          height: auto
          display: block
          position: fixed
          transform: translateX(-3vw)
        &:hover
          .info-label
            opacity: 1

      p
        margin: 0
        font-weight: bold
        font-size: .75rem
    svg
      display: block
      width: 3rem
      &.battery
        fill: $battery
        stroke: $battery
      &.bulb
        fill: $bulb
        stroke: $bulb
      &.sun
        fill: $sun
        stroke: $sun
      &.hydrogen
        fill: $hydrogen
        stroke: $hydrogen
      &.solar_thermal
        fill: $solar_thermal
        stroke: $solar_thermal
  @media (min-width: 768px)
    & > .data-section-header
      padding: 16px 48px
      margin: 0 -48px
      button
        margin: -16px 0 -16px -48px
