.navItem.Mui-expanded
    background-color: #fff
    border: 1px black
    margin-top: -8px!important
    z-index: 4
    box-shadow: 0px 0px 2px 1px gray
    width: min-content
    margin-left: 12px
    

.navButton
    text-decoration: none!important
    text-transform: none!important
    color: black!important
    border-radius: 9px!important
    font-size: 15px!important

    &:hover
        background-color: #96c8d3!important

.navItem
    margin: auto
    background-color: #FFF!important
    height: 50px
    padding-top: 14px
    font-size: 15px!important

    

    &:hover 
        background-color: #96c8d3!important

.about  
    margin-right: 75px





   


        
    

